import React, { useState, useEffect, useMemo } from "react";
import { useLeads } from "../ContextApi/leadsContextApi";
import predefinedSMSMessages from "../components/predefinedSMSMessages";
import { toast } from "react-toastify";

const LeadPage = () => {
  const { leads, getAssignedLeads, updateLead } = useLeads();
  const [statusFilter, setStatusFilter] = useState("pending");
  const [currentLeadIndex, setCurrentLeadIndex] = useState(0);
  const [customMessage] = useState(predefinedSMSMessages.welcome);
  const [newStatus, setNewStatus] = useState("pending");

  // Memoize the filtered leads to avoid unnecessary filtering on re-renders
  const filteredLeads = useMemo(() => {
    return leads.filter((lead) => lead.status === statusFilter);
  }, [leads, statusFilter]);

  useEffect(() => {
    getAssignedLeads(); // Load leads when component mounts
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNext = () => {
    if (currentLeadIndex < filteredLeads.length - 1) {
      setCurrentLeadIndex(currentLeadIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentLeadIndex > 0) {
      setCurrentLeadIndex(currentLeadIndex - 1);
    }
  };

  const handleCall = (phone) => {
    const phoneNumber = `tel:${phone}`;
    window.open(phoneNumber, "_blank");
  };

  const handleWhatsApp = (phone) => {
    const message = encodeURIComponent(
      customMessage.replace("NAME", filteredLeads[currentLeadIndex].name)
    );
    const phoneNumber = `https://wa.me/${phone}?text=${message}`;
    window.open(phoneNumber, "_blank");
  };

  const handleSms = (phone) => {
    const message = encodeURIComponent(
      customMessage.replace("NAME", filteredLeads[currentLeadIndex].name)
    );
    const smsUrl = `sms:${phone}?body=${message}`;
    window.open(smsUrl, "_blank");
  };

  const handleMail = (email) => {
    const message = encodeURIComponent(
      customMessage.replace("NAME", filteredLeads[currentLeadIndex].name)
    );
    const mailUrl = `mailto:${email}?subject=Introduction&body=${message}`;
    window.location.href = mailUrl;
  };

  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (filteredLeads.length > 0 && currentLeadIndex < filteredLeads.length) {
        const updatedLead = {
          ...filteredLeads[currentLeadIndex],
          status: newStatus, // New status from the select input
        };
        toast.success("Lead status updated successfully!");
        await updateLead(updatedLead);
      } else {
        toast.error("No lead selected or filtered leads are empty.");
      }
    } catch (error) {
      console.error("Failed to update lead status:", error);
      toast.error("Failed to update lead status. Please try again.");
    }
  };

  return (
    <div className="bg-white min-h-screen flex items-center justify-center p-6">
      <div className="w-full max-w-7xl mx-auto mt-16">
        <h1 className="text-2xl w-full text-center font-semibold mb-6 text-gray-700">
          Leads Management
        </h1>

        {/* Dropdown and buttons in one row */}
        <div className="flex items-center justify-between mb-4 space-x-4">
          <select
            className="bg-white border border-[#334774] px-4 py-2 rounded-md text-[#334774] focus:outline-none focus:ring-0 w-full sm:w-auto"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option className="text-[#334774] text-xs">Pending</option>
            <option className="text-[#334774] text-xs">Not Connected</option>
            <option className="text-[#334774] text-xs">Not Interested</option>
            <option className="text-[#334774] text-xs">Call Back</option>
            <option className="text-[#334774] text-xs">Follow Up</option>
            <option className="text-[#334774] text-xs">Doubt</option>
            <option className="text-[#334774] text-xs">Converted</option>
            <option className="text-[#334774] text-xs">Lost</option>
            <option className="text-[#334774] text-xs">Other</option>
          </select>

          <div className="flex items-center space-x-4">
            <button
              className="bg-[#334774] border border-white text-white px-4 py-2 rounded hover:bg-white hover:border-[#334774] hover:text-[#334774] ease-in-out duration-300 focus:outline-none"
              onClick={handlePrev}
              disabled={currentLeadIndex <= 0}
            >
              Previous
            </button>
            <button
              className="bg-[#334774] border border-white text-white px-4 py-2 rounded hover:bg-white hover:border-[#334774] hover:text-[#334774] ease-in-out duration-300 focus:outline-none"
              onClick={handleNext}
              disabled={currentLeadIndex >= filteredLeads.length - 1}
            >
              Next
            </button>
          </div>
        </div>

        {/* Displaying lead details */}
        {filteredLeads.length > 0 ? (
          <div className="bg-white p-6 shadow-lg rounded-lg">
            <div className="mb-4">
              <h2 className="text-xl font-bold text-gray-700">Lead Details</h2>
              <p>
                <strong>Name:</strong> {filteredLeads[currentLeadIndex].name}
              </p>
              <p>
                <strong>Phone:</strong> {filteredLeads[currentLeadIndex].phone}
              </p>
              <p>
                <strong>Email:</strong> {filteredLeads[currentLeadIndex].email}
              </p>
              <p>
                <strong>Status:</strong>{" "}
                {filteredLeads[currentLeadIndex].status}
              </p>
              <p>
                <strong>Assigned To:</strong>{" "}
                {filteredLeads[currentLeadIndex].assignedTo
                  ? `${filteredLeads[currentLeadIndex].assignedTo.name}`
                  : "-"}
              </p>
              <p>
                <strong>Added By:</strong>{" "}
                {filteredLeads[currentLeadIndex].addedBy
                  ? `${filteredLeads[currentLeadIndex].addedBy.name}`
                  : "-"}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(
                  filteredLeads[currentLeadIndex].createdAt
                ).toLocaleString()}
              </p>
              <p>
                <strong>Updated At:</strong>{" "}
                {new Date(
                  filteredLeads[currentLeadIndex].updatedAt
                ).toLocaleString()}
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-white p-6 shadow-lg min-h-[50vh] rounded-lg flex flex-col items-center justify-center space-y-4">
            <svg
              className="w-16 h-16 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 14c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM12 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zM12 8c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zM6 21c0-3.31 2.69-6 6-6s6 2.69 6 6H6z"
              />
            </svg>
            <p className="text-gray-500 text-center">
              No leads / data available
            </p>
          </div>
        )}

        <p className="mt-3 w-full text-center text-gray-500">
          {`Lead ${currentLeadIndex + 1} of ${filteredLeads.length}`}
        </p>

        <div className="w-full justify-center items-center flex flex-col">
          {/* WhatsApp, Call, SMS, Mail Buttons */}
          <div className="flex space-x-4 mt-4">
            <button
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={() => handleCall(filteredLeads[currentLeadIndex].phone)}
            >
              Call
            </button>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={() =>
                handleWhatsApp(filteredLeads[currentLeadIndex].phone)
              }
            >
              WhatsApp
            </button>
            <button
              className="bg-yellow-500 text-white px-4 py-2 rounded"
              onClick={() => handleSms(filteredLeads[currentLeadIndex].phone)}
            >
              SMS
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => handleMail(filteredLeads[currentLeadIndex].email)}
            >
              Mail
            </button>
          </div>

          {/* Status Update Form */}
          <div className="mt-6 w-full">
            <h2 className="text-lg font-bold text-gray-700 mb-2">
              Update Lead Status
            </h2>
            <div className=" flex flex-row justify-center items-center  gap-2">
              <select
                className="w-full border border-gray-300 rounded-md px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={newStatus}
                onChange={handleStatusChange}
              >
                <option className="text-gray-700 text-xs">Pending</option>
                <option className="text-gray-700 text-xs">Not Connected</option>
                <option className="text-gray-700 text-xs">
                  Not Interested
                </option>
                <option className="text-gray-700 text-xs">Call Back</option>
                <option className="text-gray-700 text-xs">Follow Up</option>
                <option className="text-gray-700 text-xs">Doubt</option>
                <option className="text-gray-700 text-xs">Converted</option>
                <option className="text-gray-700 text-xs">Lost</option>
                <option className="text-gray-700 text-xs">Other</option>
              </select>
              <button
                className=" bg-[#334774] text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadPage;
