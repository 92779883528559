import React from "react";
import { Helmet } from "react-helmet";
import { useUser } from "../ContextApi/userContextApi";
import { Link } from "react-router-dom";

const Footer = () => {
  const { user } = useUser();

  return (
    <footer class="w-full border-t border-[#334774] ">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="py-10 flex justify-between items-center flex-col gap-8 xl:flex-row">
          <div class="flex items-center flex-col xl:flex-row ">
            <Link to="/" class="flex justify-center mb-3 xl:mb-0">
              <img
                src="https://res.cloudinary.com/dcz2bgh12/image/upload/v1732856682/main_logo.7fdc6d75bbce647c71ba_jfle0s.webp"
                className="h-12"
                alt=""
              />
            </Link>
            <ul class="text-lg  flex items-center flex-col md:flex-row  py-4 gap-6 md:gap-12 xl:border-l border-gray-200 xl:ml-11 xl:pl-11 transition-all duration-500">
              <li>
                <a href="#" class="text-gray-800 hover:text-indigo-600">
                  DashBoard
                </a>
              </li>
              <li>
                <a href="#" class="text-gray-800 hover:text-indigo-600">
                  Login
                </a>
              </li>
              <li>
                <a href="#" class="text-gray-800 hover:text-indigo-600">
                  Register
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-800 hover:text-indigo-600">
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div class="flex space-x-4 sm:justify-center">
            <a
              href="https://github.com/ShivRajSingh-SDE"
              target="_blank"
              rel="noopener noreferrer"
              class="group w-9 h-9 rounded-full border border-gray-300 flex justify-center items-center transition-all duration-500 hover:border-indigo-600"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/25/25231.png"
                alt="GitHub"
                class="w-6 h-6 text-gray-700 transition-all duration-500 group-hover:text-indigo-600"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/shivraj100x/"
              target="_blank"
              rel="noopener noreferrer"
              class="group w-9 h-9 rounded-full border border-gray-300 flex justify-center items-center transition-all duration-500 hover:border-indigo-600"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                alt="LinkedIn"
                class="w-6 h-6 text-gray-700 transition-all duration-500 group-hover:text-indigo-600"
              />
            </a>

            <a
              href="https://www.instagram.com/shiv_not_ok/"
              target="_blank"
              rel="noopener noreferrer"
              class="group w-9 h-9 rounded-full border border-gray-300 flex justify-center items-center transition-all duration-500 hover:border-indigo-600"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/174/174855.png"
                alt="Instagram"
                class="w-6 h-6 text-gray-700 transition-all duration-500 group-hover:text-indigo-600"
              />
            </a>
          </div>
        </div>

        <div class="py-7 border-t border-gray-200">
          <div class="flex items-center justify-center">
            <span class="text-gray-400 ">
              ©<a href="/">Wellme Pipes</a> 2024, All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
