import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminPage from "../Pages/adminPage";

const Admin = () => {
  return (
    <Routes>
      <Route path="/home" element={<AdminPage />} />
    </Routes>
  );
};

export default Admin;
