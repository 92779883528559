import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function Notfound() {
  const location = useLocation();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white text-[#334774]">
      <div className="bg-opacity-95 backdrop-filter backdrop-blur-lg rounded-lg p-10 text-center  max-w-xl w-full ">
        <h1 className="text-6xl font-extrabold text-[#334774] mb-4">404</h1>
        <p className="text-2xl mb-4">Oops! It seems you're lost.</p>
        <p className="text-sm mb-6">
          The page you’re looking for doesn’t exist.
        </p>

        <svg
          className="mx-auto h-24 w-24 text-[#334774] mb-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
          />
        </svg>

        <p className="mt-6 text-lg">
          Let’s get you back{" "}
          <NavLink
            to="/"
            className="text-blue-400 hover:underline transition duration-300"
          >
            Home
          </NavLink>
          .
        </p>
      </div>
    </div>
  );
}
