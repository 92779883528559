import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./ContextApi/userContextApi";
import LeadsContextProvider from "./ContextApi/leadsContextApi"; // Import the LeadsContext
import Routesroute from "./Routes/Routesroute";
import Header from "./Pages/Header";
import Footer from "./Pages/Footer";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <UserContextProvider>
      <LeadsContextProvider>
        {" "}
        {/* Wrap the application with LeadsContextProvider */}
        <Header />
        <Routesroute />
        <Footer />
      </LeadsContextProvider>
    </UserContextProvider>
  </BrowserRouter>
);
