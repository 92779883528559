import React, { createContext, useContext, useReducer, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const UserContext = createContext();

const backend = "https://telecaller-crm.onrender.com/api/auth";
const api = backend;

const initialState = {
  isUserLoading: false,
  isUserError: false,
  user: Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
  token: Cookies.get("token") || null,
  isLoggedOut: false,
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_LOADING":
      return { ...state, isUserLoading: true, isUserError: false };
    case "SET_USER_DATA":
      if (action.payload && action.payload.user && action.payload.token) {
        const { user, token } = action.payload;
        Cookies.set("user", JSON.stringify(user), { expires: 7 });
        Cookies.set("token", token, { expires: 7 });

        return {
          ...state,
          isUserLoading: false,
          user,
          token,
          isLoggedOut: false,
        };
      } else {
        console.error("Invalid payload in SET_USER_DATA:", action.payload);
      }
      return state;

    case "LOGOUT_USER":
      Cookies.remove("user");
      Cookies.remove("token");
      return { ...state, user: null, token: null, isLoggedOut: true };
    case "SET_USER_ERROR":
      return { ...state, isUserError: true, isUserLoading: false };
    default:
      return state;
  }
};

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const loginUser = async (email, password) => {
    dispatch({ type: "SET_USER_LOADING" });
    try {
      const response = await axios.post(`${api}/login`, { email, password });
      console.log("Response data:", response.data);
      dispatch({ type: "SET_USER_DATA", payload: response.data });
    } catch (error) {
      dispatch({ type: "SET_USER_ERROR" });
      throw new Error("Invalid email or password");
    }
  };

  const registerUser = async (username, email, password) => {
    dispatch({ type: "SET_USER_LOADING" });
    try {
      const response = await axios.post(`${api}/register`, {
        username,
        email,
        password,
      });
      dispatch({ type: "SET_USER_DATA", payload: response.data });
    } catch (error) {
      dispatch({ type: "SET_USER_ERROR" });
      throw error;
    }
  };

  const logoutUser = () => {
    dispatch({ type: "LOGOUT_USER" });
  };

  const getUser = async () => {
    dispatch({ type: "SET_USER_LOADING" });
    try {
      const token = Cookies.get("token");
      if (token) {
        const response = await axios.get(`${backend}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch({ type: "SET_USER_DATA", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "SET_USER_ERROR" });
    }
  };

  useEffect(() => {
    if (!state.user) {
      getUser();
    }
  }, [state.user]);

  return (
    <UserContext.Provider
      value={{ ...state, loginUser, registerUser, logoutUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const userContextValue = useContext(UserContext);
  if (!userContextValue) {
    throw new Error("useUser must be used within a UserContextProvider");
  }
  return userContextValue;
};

export default UserContextProvider;
