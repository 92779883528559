import React from "react";

const admin = () => {
  return (
    <div className=" flex flex-col justify-center items-center h-screen">
      admin
    </div>
  );
};

export default admin;
