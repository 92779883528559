const predefinedSMSMessages = {
  welcome: `
      Welcome to WELLME PIPES Pvt. Ltd.
      At WELLME PIPES, we specialize in manufacturing and supplying high-quality PVC CONDUIT PIPES and fittings for electrical and construction industries. Our commitment to innovation, quality, and customer satisfaction makes us a trusted name in the market. Get high-quality PVC conduit pipes & accessories at competitive prices. 
      🔎 Get in Touch
      INSTAGRAM: https://bit.ly/4e6SNbr 
      FACEBOOK: https://bit.ly/4e83wSL
      YOUTUBE: https://bit.ly/3ZrfWAR
      LINKEDIN: https://bit.ly/3XzdmWC
      🌎 www.wellmepipes.com
      📞 +919855955985
      📧 wellmepipes@gmail.com
      🛒 https://products.wellmepipes.com/
      Please share your visiting card or address of your shop for further details`,
  inquiry: `
      Hi [Name],  
      Thank you for showing interest in WELLME PIPES Pvt. Ltd. We specialize in manufacturing and supplying high-quality PVC CONDUIT PIPES and fittings. Please share your details, and we'll get back to you shortly.  
      🔎 Get in Touch
      INSTAGRAM: https://bit.ly/4e6SNbr 
      FACEBOOK: https://bit.ly/4e83wSL
      YOUTUBE: https://bit.ly/3ZrfWAR
      LINKEDIN: https://bit.ly/3XzdmWC
      🌎 www.wellmepipes.com
      📞 +919855955985
      📧 wellmepipes@gmail.com
      🛒 https://products.wellmepipes.com/`,
  followUp: `
      Hello [Name],  
      This is a gentle reminder regarding your inquiry about WELLME PIPES Pvt. Ltd. We would love to assist you with any questions or details you need. Feel free to get in touch!  
      🔎 Get in Touch
      INSTAGRAM: https://bit.ly/4e6SNbr 
      FACEBOOK: https://bit.ly/4e83wSL
      YOUTUBE: https://bit.ly/3ZrfWAR
      LINKEDIN: https://bit.ly/3XzdmWC
      🌎 www.wellmepipes.com
      📞 +919855955985
      📧 wellmepipes@gmail.com
      🛒 https://products.wellmepipes.com/`,
};

export default predefinedSMSMessages;
