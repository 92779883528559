import React, { createContext, useContext, useReducer, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const LeadsContext = createContext();

const backend = "https://telecaller-crm.onrender.com/api/leads";
const api = backend;

const initialState = {
  isLeadsLoading: false,
  isLeadsError: false,
  leads: [],
};

const leadsReducer = (state, action) => {
  switch (action.type) {
    case "SET_LEADS_LOADING":
      return { ...state, isLeadsLoading: true, isLeadsError: false };
    case "SET_LEADS_DATA":
      return {
        ...state,
        isLeadsLoading: false,
        leads: action.payload,
      };
    case "ADD_LEAD":
      return { ...state, leads: [...state.leads, action.payload] };
    case "UPDATE_LEAD":
      return {
        ...state,
        leads: state.leads.map((lead) =>
          lead._id === action.payload._id ? action.payload : lead
        ),
      };
    case "DELETE_LEAD":
      return {
        ...state,
        leads: state.leads.filter((lead) => lead._id !== action.payload),
      };
    case "SET_LEADS_ERROR":
      return { ...state, isLeadsError: true, isLeadsLoading: false };
    default:
      return state;
  }
};

const LeadsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(leadsReducer, initialState);

  const getAllLeads = async () => {
    dispatch({ type: "SET_LEADS_LOADING" });
    try {
      const response = await axios.get(api);
      dispatch({ type: "SET_LEADS_DATA", payload: response.data });
    } catch (error) {
      dispatch({ type: "SET_LEADS_ERROR" });
    }
  };

  const getAssignedLeads = async () => {
    dispatch({ type: "SET_LEADS_LOADING" });
    const token = Cookies.get("token"); // Retrieve token from cookies

    try {
      const response = await axios.get(`${api}/assigned`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: "SET_LEADS_DATA", payload: response.data });
    } catch (error) {
      dispatch({ type: "SET_LEADS_ERROR" });
    }
  };

  const addLead = async (leadData) => {
    dispatch({ type: "SET_LEADS_LOADING" });
    try {
      const response = await axios.post(api, leadData);
      dispatch({ type: "ADD_LEAD", payload: response.data });
    } catch (error) {
      dispatch({ type: "SET_LEADS_ERROR" });
    }
  };

  const updateLead = async (updatedData) => {
    dispatch({ type: "SET_LEADS_LOADING" });
    const token = Cookies.get("token");

    try {
      const response = await axios.put(
        `${api}/${updatedData._id}`,
        updatedData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({ type: "UPDATE_LEAD", payload: response.data });
      return toast.success("Lead status updated successfully!");
    } catch (error) {
      dispatch({ type: "SET_LEADS_ERROR" });
      return toast.error("Failed to update lead status. Please try again.");
    }
  };

  const deleteLead = async (leadId) => {
    dispatch({ type: "SET_LEADS_LOADING" });
    try {
      await axios.delete(`${api}/${leadId}`);
      dispatch({ type: "DELETE_LEAD", payload: leadId });
    } catch (error) {
      dispatch({ type: "SET_LEADS_ERROR" });
    }
  };

  useEffect(() => {
    getAllLeads();
  }, []);

  return (
    <LeadsContext.Provider
      value={{
        ...state,
        getAllLeads,
        getAssignedLeads,
        addLead,
        updateLead,
        deleteLead,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export const useLeads = () => {
  const leadsContextValue = useContext(LeadsContext);
  if (!leadsContextValue) {
    throw new Error("useLeads must be used within a LeadsContextProvider");
  }
  return leadsContextValue;
};

export default LeadsContextProvider;
