import React, { useEffect, useMemo, useState } from "react";
import { useUser } from "../ContextApi/userContextApi.jsx";
import { useLeads } from "../ContextApi/leadsContextApi.jsx";
import { Link } from "react-router-dom";

const Home = () => {
  const { user } = useUser();
  const { leads, getAssignedLeads, updateLead } = useLeads();
  const [statusFilter, setStatusFilter] = useState("pending");

  // Memoize the filtered leads to avoid unnecessary filtering on re-renders
  const filteredLeads = useMemo(() => {
    return leads.filter((lead) => lead.status === statusFilter);
  }, [leads, statusFilter]);

  // Calculate lead counts by status using useMemo
  const leadCountsByStatus = useMemo(() => {
    return leads.reduce((acc, lead) => {
      if (!acc[lead.status]) {
        acc[lead.status] = 0;
      }
      acc[lead.status] += 1;
      return acc;
    }, {});
  }, [leads]);

  useEffect(() => {
    getAssignedLeads(); // Load leads when component mounts
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!user) {
    return (
      <div className="h-screen flex items-center justify-center bg-white">
        <h1 className="text-gray-800">Loading user data...</h1>
      </div>
    );
  }

  // Get the current hour to determine the appropriate greeting
  const currentHour = new Date().getHours();
  let greeting = "";

  if (currentHour < 5) {
    greeting = "Good Night";
  } else if (currentHour < 12) {
    greeting = "Good Morning";
  } else if (currentHour < 17) {
    greeting = "Good Afternoon";
  } else if (currentHour < 21) {
    greeting = "Good Evening";
  } else {
    greeting = "Welcome";
  }

  // Format the last logged-in date
  const formattedLastLoggedIn = new Date(user.lastLoggedIn).toLocaleString();

  return (
    <div className="h-auto bg-[#334774] flex items-center justify-center md:p-8 p-4">
      <div className="max-w-lg w-full bg-[#ffffff] flex flex-col justify-center items-center border border-[#334774] rounded-lg mt-20 shadow-lg md:p-8 p-4">
        <h1 className="md:text-4xl text-xl text-[#334774] md:mb-8 mb-3 text-center font-bold">
          {greeting}, {user.name} Ji
        </h1>
        <div className="max-w-2xl w-full bg-white border border-[#334774] rounded-lg shadow-lg md:p-8 p-3 ">
          <h2 className="text-[#334774] text-center text-2xl border-b border-[#334774] md:mb-6 mb-3 font-semibold">
            Profile
          </h2>
          <ul className="space-y-4 text-[#334774]">
            <li className="flex justify-between">
              <span className="font-medium">Name:</span> {user.name}
            </li>
            <li className="flex justify-between">
              <span className="font-medium">Email:</span> {user.email}
            </li>
            <li className="flex justify-between">
              <span className="font-medium">Role:</span> {user.role}
            </li>
            <li className="flex justify-between">
              <span className="font-medium">Last Logged In:</span>{" "}
              {formattedLastLoggedIn}
            </li>
          </ul>
        </div>

        <Link
          to="/telecaller/leads"
          className="w-full p-3 bg-[#334774] text-white text-center rounded-lg my-5"
        >
          Dashboard
        </Link>

        <div className="max-w-2xl w-full bg-white border border-[#334774] rounded-lg shadow-lg md:p-8 p-3 ">
          <h2 className="md:text-3xl text-xl text-[#334774] md:mb-8 mb-3 text-center font-bold">
            Leads by Status
          </h2>
          <ul className="space-y-4 text-[#334774]">
            {Object.keys(leadCountsByStatus).map((status) => (
              <li
                key={status}
                className="flex justify-between md:text-xl text-sm bg-[#f1f1f1] md:p-6 p-2 rounded-lg shadow-md"
              >
                <span className="md:font-medium md:text-xl text-sm">
                  Status:
                </span>{" "}
                {status}
                <span className="font-medium md:text-xl text-sm">
                  Count:
                </span>{" "}
                {leadCountsByStatus[status]}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
