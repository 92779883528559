import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useUser } from "../ContextApi/userContextApi.jsx";
import Admin from "./Admin.js";
import Home from "../components/Home";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import NotFound from "../Pages/Notfound.jsx";
import Customer from "./Custumer.js";

const RoutesWithNotification = () => {
  const { user } = useUser();

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />

      {/* Role-Based Protected Routes */}
      {user ? (
        <>
          {/* Admin Routes */}
          {user.role === "admin" ? (
            <Route path="/admin/*" element={<Admin />} />
          ) : (
            <Route path="/admin/*" element={<Navigate to="/customer" />} />
          )}

          {/* Telecaller Routes */}
          {user.role === "telecaller" ? (
            <Route path="/telecaller/*" element={<Customer />} />
          ) : (
            <Route path="/customer/*" element={<Navigate to="/admin" />} />
          )}
        </>
      ) : (
        // Redirect non-authenticated users to login
        <>
          <Route path="/admin/*" element={<Navigate to="/login" />} />
          <Route path="/telecaller/*" element={<Navigate to="/login" />} />
        </>
      )}

      {/* Catch-all route for 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RoutesWithNotification;
