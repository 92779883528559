import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../ContextApi/userContextApi";
import { HiMenu } from "react-icons/hi";
import { motion } from "framer-motion"; // Correct import

const Header = () => {
  const { user, logoutUser } = useUser();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleLogout = () => {
    logoutUser();
    navigate("/login");
  };

  return (
    <div className="fixed w-full bg-white  border-b border-[#334774]  z-50 shadow-md">
      <div className="max-w-[85%] mx-auto flex items-center justify-between py-3 px-4 md:px-6">
        {/* Logo */}
        <Link to="/" className="flex items-center space-x-2">
          <img
            src="https://res.cloudinary.com/dcz2bgh12/image/upload/v1732856682/main_logo.7fdc6d75bbce647c71ba_jfle0s.webp"
            className="h-12"
            alt="ToolzStream Logo"
          />
        </Link>

        {/* Desktop Menu */}
        <ul className="hidden md:flex items-center space-x-6">
          <li>
            <Link
              to="/"
              className="text-[#334774] hover:text-[#5a6e99] transition duration-300"
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="text-[#334774] hover:text-[#5a6e99] transition duration-300"
            >
              ABOUT
            </Link>
          </li>
          <li>
            <Link
              to="/customer/dashboard"
              className="text-[#334774] hover:text-[#5a6e99] transition duration-300"
            >
              DASHBOARD
            </Link>
          </li>
          <li>
            <Link
              to="/customer/products"
              className="text-[#334774] hover:text-[#5a6e99] transition duration-300"
            >
              PLANS
            </Link>
          </li>
          <li>
            <Link
              to="/customer/guide"
              className="text-[#334774] hover:text-[#5a6e99] transition duration-300"
            >
              GUIDE
            </Link>
          </li>
          <li>
            {user ? (
              <button
                onClick={handleLogout}
                className="bg-[#334774] text-white px-4 py-2 rounded-md hover:bg-[#5a6e99] transition duration-300"
              >
                LOGOUT
              </button>
            ) : (
              <Link
                to="/login"
                className="bg-[#334774] text-white px-4 py-2 rounded-md hover:bg-[#5a6e99] transition duration-300"
              >
                LOGIN
              </Link>
            )}
          </li>
        </ul>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden flex items-center  ">
          <HiMenu
            className="text-[#334774] text-2xl cursor-pointer"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </div>

        {/* Mobile Menu with Animation */}
        {isMenuOpen && (
          <motion.ul
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 25,
            }}
            className="fixed top-20 left-0 w-full bg-white h-screen  "
          >
            <li>
              <Link
                to="/"
                className="block text-[#334774] hover:text-[#5a6e99] px-6 py-3 border-b border-[#334774] transition duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block text-[#334774] hover:text-[#5a6e99] px-6 py-3 border-b border-[#334774] transition duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                ABOUT
              </Link>
            </li>
            <li>
              <Link
                to="/customer/dashboard"
                className="block text-[#334774] hover:text-[#5a6e99] px-6 py-3 border-b border-[#334774] transition duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                DASHBOARD
              </Link>
            </li>
            <li>
              <Link
                to="/customer/products"
                className="block text-[#334774] hover:text-[#5a6e99] px-6 py-3 border-b border-[#334774] transition duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                PLANS
              </Link>
            </li>
            <li>
              <Link
                to="/customer/guide"
                className="block text-[#334774] hover:text-[#5a6e99] px-6 py-3 border-b border-[#334774] transition duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                GUIDE
              </Link>
            </li>
            <li>
              {user ? (
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMenuOpen(false);
                  }}
                  className="block w-full bg-[#334774] text-white px-6 py-3 rounded-md hover:bg-[#5a6e99] transition duration-300"
                >
                  LOGOUT
                </button>
              ) : (
                <Link
                  to="/login"
                  className="block w-full bg-[#334774] text-white px-6 py-3 rounded-md hover:bg-[#5a6e99] transition duration-300"
                  onClick={() => setIsMenuOpen(false)}
                >
                  LOGIN
                </Link>
              )}
            </li>
          </motion.ul>
        )}
      </div>
    </div>
  );
};

export default Header;
